
#template {
	display: none;
}

@media (min-width: 992px) {
.canvas-width{
	width:35% !important;
}
}
@media (min-width: 992px) {
.prescriptioncard{
	width:70% !important;
}
}


